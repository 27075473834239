<template>
<div>
 
<el-breadcrumb separator-class="el-icon-arrow-right">
   <el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
  <el-breadcrumb-item>会员管理</el-breadcrumb-item>
  <el-breadcrumb-item>会员列表</el-breadcrumb-item>
</el-breadcrumb>
 <el-card  style="  overflow: auto; ">
 <!-- 分类搜索 -->
      <el-row>
        <el-col class="mr15" :span="3.3">
          <el-input size="mini" placeholder="会员手机号" @input="keyword" v-model="queryInfo.keyword">
            <el-button slot="append" icon="el-icon-search"/>
          </el-input>
        </el-col>
     <!-- <el-col :span="11" style="display: flex;align-items: center;">
          <el-date-picker
              size="mini"
              v-model="CheckInTime"
              type="datetimerange"
              range-separator="至"
              start-placeholder="注册时间"
              end-placeholder="注册时间"
              @change="timeHandler"
              clearable>
          </el-date-picker>
        </el-col> -->
      </el-row>
 <!--用户列表数据展示区域-->
      <el-table
        v-loading="loading"
        :data="tableData"
        style="width: 100%"
      
        ref="singleTable"
        highlight-current-row
         :header-cell-style="{'text-align':'center'}"    
       :cell-style="{'text-align':'center'}"
        row-key="id"
        stripe >
     <el-table-column  prop="id" label="会员ID" width="100" show-overflow-tooltip/>
       <el-table-column prop="mobile" label="头像" width="150">
          <template  slot-scope="scope">
            <img @click="onimg(scope.row.headImg)" style="margin:  0 0 0 20px;" :src="scope.row.headImg" alt="" width="50px" />
          </template>
        </el-table-column>
        <el-table-column prop="nam" label="钱包" width="200" show-overflow-tooltip>
         <template  slot-scope="scope" v-if="scope.row.userPayJson">
        <p style=" float: left;padding-left:10px ;" v-if="JSON.parse(scope.row.userPayJson).wei_chat==1">微信</p>
        <p style="float: left;padding-left:10px ;" v-if="JSON.parse(scope.row.userPayJson).bank_card==1">银行卡</p>
       <p style="float: left;padding-left:10px ;" v-if="JSON.parse(scope.row.userPayJson).alipay==1">支付宝</p>
        </template>
        </el-table-column>
        <el-table-column prop="mobile" label="手机号" width="250" show-overflow-tooltip />
        <el-table-column prop="userName" label="昵称" width="250" show-overflow-tooltip />
        <el-table-column prop="createTime" label="注册时间" width="250" show-overflow-tooltip/> 
        <el-table-column label="性别" width="150" show-overflow-tooltip>
          <template  slot-scope="scope">
            <p v-if="scope.row.gender==1">男</p>
            <p v-else>女</p>
          </template>
        </el-table-column>
         <el-table-column prop="mobile" label="用户等级" width="150"  >
          <template  slot-scope="scope">
          <p v-if="scope.row.userLevel==0">会员</p>
           <p v-else-if="scope.row.userLevel==1">达人</p>
            <p v-else >团长</p>
          </template>
        </el-table-column>
           <el-table-column prop="mobile" label="用户身份" width="150"  >
          <template slot-scope="scope">
          <p v-if="scope.row.userLevel==0">用户</p>
           <p v-else>商家</p>
          </template>
          </el-table-column>
        <el-table-column label="操作" min-width="100" fixed="right">
           <template  slot-scope="scope">
          <el-button @click=" user(scope.row.id)" size="mini"  type="text">查看</el-button>
          <!-- <el-button v-if="scope.row.userState==0" size="mini" type="text">停用</el-button>
          <el-button v-else size="mini" type="success">启用</el-button> -->
          </template>
        </el-table-column>
      </el-table>
      <!--分页-->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.page"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="queryInfo.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
 </el-card>

 <!-- 弹窗查看 -->
 <el-dialog
  title="会员详情"
  class="abow_dialog"
  :visible.sync="dialogVisible"
  width="30%"
  :append-to-body="true"
>
 <div class="userDetail">
        <div class="avatar">
        <div @click="onimg(userDataById.headImg)">
          <el-avatar  :src="userDataById.headImg"></el-avatar>
          </div>
          <p style=" margin-top:10px ; ">{{ userDataById.userName}}</p>
        </div>
        <p style=" margin-top:10px ; width: 100%;">注册时间：{{ userDataById.createTime }}</p>
        <p style="width: 100%;  margin-top:10px ;">用户身份：{{ userRole }}</p>
          <p style="width: 100%;  margin-top:10px ;">	用户等级：{{ userDataById.userLevel }}</p>
        <p style="width: 100%;  margin-top:10px ;">邮箱：{{ userDataById.userEmail }}</p>
      
        <p style="width: 100%;  margin-top:10px ;">积分：{{ userDataById.userMobile }}</p>
        <p style="width: 100%;  margin-top:10px ;">	手机号：{{ userDataById.mobile }}</p>
   
       
        <p style="width: 100%;  margin-top:10px ;"> 用户状态：{{ userDataById.userState }}</p>
      <p style="width: 100%;  margin-top:10px ;">收货地址：{{ userDataById.receiveAddressJson }}</p>
     <p style="width: 100%;  margin-top:10px ;">设备终端：{{ userDataById.terminal }}</p>
     
      </div>
      <span slot="footer">
        <el-button type="primary" @click="ViewUserDialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>
   <el-dialog   :visible.sync="imgsVisible" :append-to-body="true">
   <img width="100%" :src="imgs" alt="">
   </el-dialog>
</div>
</template>
<script>
import time from '../../utils/time'
import { getPage,searchUserInfoById} from "@/api";
export default {
data(){
    return{
        dialogVisible:false,
        tableData:[],
      loading:true,
      // 查看用户详情
     userDataById:{

      },
      // 查找商品列表参数对象
      queryInfo: {
        current: 1,
      size: 10,
      
       keyword:"",
       page:1,
 
      }, 
      total: 0,
      userRole:0,
      imgs:"",
      imgsVisible:false,

    };
  
},
created(){
  
this.getPage()
},
   methods: {
    onimg(e){
 
this.imgs=e
this.imgsVisible=true
    },
    // 用户详情
  async  user(id){
  const {data: res} = await searchUserInfoById({id:id})

  this.userRole=res.body.userIdentity==0?"用户":res.body.userIdentity==1?"商家":res.body.userIdentity==2?"代驾":res.body.userIdentity==10?"平台":""
   this.userDataById.receiveAddressJson
this.userDataById=res.body
 this.userDataById.userLevel=res.body.userLevel==0?"会员":res.body.userIdentity==1?"达人":res.body.userIdentity==2?"团长":""
  this.userDataById.terminal=res.body.terminal==0?"安卓":res.body.terminal==1?"IOS":res.body.terminal==2?"小程序":""
   this.userDataById.userState=res.body.userState==0?"正常":res.body.userState==1?"冻结":''
   if(res.body.receiveAddressJson){
this.userDataById.receiveAddressJson=JSON.parse(res.body.receiveAddressJson)[0].province+JSON.parse(res.body.receiveAddressJson)[0].city+JSON.parse(res.body.receiveAddressJson)[0].district+JSON.parse(res.body.receiveAddressJson)[0].address
   }
 
     if(this.userDataById.createTime){
this.userDataById.createTime= time(this.userDataById.createTime)
    }

this.dialogVisible = true
    },
    //  模糊搜索
    keyword(){
this.total=0
this.getPage()
    },
    //  获取用户信息
   async getPage(){
  const {data: res} = await getPage(this.queryInfo)
  this.tableData=res.body.list
  for (let index = 0; index < this.tableData.length; index++) {
    if(this.tableData[index].createTime){
this.tableData[index].createTime= time(this.tableData[index].createTime)
    }
 
}
    this.loading=false
    this.total=res.body.total
    },
  handleCurrentChange(data){

this.queryInfo.page=data

this.getPage()
    },
// 选择每页展示数量
handleSizeChange(data){
  this.queryInfo.page = 1;
this.queryInfo.size=data
 
this.getPage()
},
       // 退出
    logout() {
      window.sessionStorage.clear()
      this.$router.push('/login')
    },
    // 返回首页
    backHome(){
      this.$router.push('/index')
      window.sessionStorage.removeItem('activePath')
  
      
    }
    }
}
</script>

<style lang="scss">
.el-information{
    .el-col{
        margin-bottom:15px ;
    }
}
.el-icon-s-fold,.el-icon-s-unfold{
    font-size: 35px;
    margin:22px ;
    color: #999;
}
.mr15 {
  margin-right: 15px;
}

.avatar {
  display: flex;
height: 50px;
  .el-avatar {
    margin-right: 20px;
  }
}
.userDetail{
  font-weight: bold;
}
.herParent {
  display: flex;
  align-items: center
}

.CheckInTimeTitle {
  
  font-size: 12px;
  color: #606266;
  margin-right: 5px;
  width: 60px;
}
</style>