
	function time(date) {
		if(date){
			var dateee = new Date(date).toJSON();

			return new Date(+new Date(dateee) + 8 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '')
		}else{
			return ""
		}
	
		//这样就不会少8个小时了
	}

    export default time